@import url(https://fonts.googleapis.com/css2?family=Dongle:wght@300;400&family=Roboto:wght@300;400&family=Rubik:ital,wght@1,900&display=swap);
@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@900&display=swap');
*{
    padding:0;
    margin:0;
    box-sizing:border-box
}
body::-webkit-scrollbar{
    width:10px
}
body::-webkit-scrollbar-track{
    -webkit-box-shadow:5px 5px 5px -5px rgba(34,60,80,.2) inset;
    background-color:var(--glass-card,rgba(35,38,48,.5));
    border-radius:10px
}
body::-webkit-scrollbar-thumb{
    width:20px;
    border-radius:10px;
    background:linear-gradient(180deg,#00c6fb,#005bea)
}
.b:lang(ru),.block__fire:lang(ru),.btn__content:lang(ru),.btn__content.desc__calc:lang(ru),.btn__spin:lang(ru),.card__text,.center__block:lang(ru),.content__footer p:lang(ru),.nav:lang(ru),.now:lang(ru),.play:lang(ru),.result .btn__content:lang(ru),.title__book:lang(ru),h5:lang(ru){
    font-family:Roboto,sans-serif;
    font-weight:400;
    font-size:14px
}

.result__top .btn__content:lang(ru){
    font-family:Roboto,sans-serif;
    font-weight:900;
    font-size:10px
}
#click:lang(ru){
    line-height:47px
}

.qwid h4:lang(ru){
    font-family:Roboto,sans-serif;
    font-weight:900;
    font-size:12px
}

.card__text:lang(ru),.r:lang(ru){
    width:500px;
    height:30px;
    font-family:Roboto,sans-serif;
    font-weight:900;
    font-size:18px;
    top:8px
}
#text2:lang(ru){
    left:114%
}
#text2-2:lang(ru){
    left:167%
}

.calcul:lang(ru),.mob_title:lang(ru),h2:lang(ru),h3:lang(ru),h4:lang(ru){
    font-family:Roboto,sans-serif;
    font-weight:900;
    font-size:20px
}


.block__fire .btn__content:lang(ru),.content__footer p:lang(ru),.j:lang(ru){
    font-family:Roboto,sans-serif;
    top:24px;
    font-size:14px
}
#loader{
    display:block;
    position:fixed;
    top:0;
    left:0;
    width:100%;
    height:100%;
    background-color:#1c1f28;
    background-image:url(../../../src/img/loader.gif);
    background-size:60px 60px;
    background-repeat:no-repeat;
    background-position:center;
    z-index:9999
}
.loader-animation{
    position:absolute;
    top:50%;
    left:50%;
    transform:translate(-50%,-50%);
    width:50px;
    height:50px
}
p{
    color:var(--text-1,#fff);
    text-align:center;
    font-size:20px;
    font-family:Dongle;
    line-height:22px
}
.result__bottom .block__bank{
    padding-bottom: 30px;
}
 .result .block__bank{
    width:248px;
    position:absolute;
    left:47px;
    top:211px
}
.card__text,.title__book{
    text-align: left;
    font-size:24px;
    font-family:Rubik;
    font-style:italic;
    font-weight:900;
    line-height:normal;
    text-transform:uppercase
}
h2,h3,h4{
    color:var(--text-1,#fff);
    text-align:center;
    font-size:40px;
    font-family:Rubik;
    font-style:italic;
    font-weight:900;
    text-transform:uppercase
}
h3{
    font-size:24px
}
h4{
    margin-top:32px;
    font-size:16px;
}
.btn__spin,.j,button,h5{
    color:var(--text-1,#fff);
    text-align:center;
    font-size:32px;
    font-family:Dongle;
    line-height:35px;
    text-transform:uppercase
}
.btn__spin{
    font-size:20px;
    line-height:30px;
    text-transform:none;
    cursor:pointer
}
h5{
    background:linear-gradient(180deg,#f08019 0,#f6cd3a 100%);
    -webkit-background-clip:text;
    -webkit-text-fill-color:transparent;
    font-size:20px;
    font-weight:300;
    line-height:16px
}
.spn__bank h5{
    line-height: 37px;
}
.spn__bank span{
    line-height: 14px;
     font-size:36px;
    font-family:Rubik;
    font-style:italic;
    font-weight:900;
    background: var(--Important-elements, linear-gradient(180deg, #F08019 0%, #F6CD3A 100%));
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.spn__bank{
    margin: 0 auto;
    width: 250px;
    height: 100px;
    border-radius: 20px;
    background: var(--Dark-Elements, rgba(0, 0, 0, 0.20));
}
.spn__bank:not(:first-child){
    margin-top:18px
}
.spn__bank{
    text-align:center;
}
.b,.btn__content{
    color:#fff;
    text-align:center;
    font-size:24px;
    font-family:Dongle;
    font-weight:300;
    line-height:16px
}
.r{
    font-size:16px;
    font-family:Rubik;
    font-style:italic;
    font-weight:900
}
body{
    overflow-x:hidden;
    background-color:#1c1f28;
    height:100%;
    margin:0 auto
}
.result__bottom h4{
    margin-top: 0;
}
.result__bottom{
    display:flex;
    flex-direction: column;
    justify-content: center;
     width: 340px;
     height:463px;
     position: relative;
    border-radius:20px;
    margin-left: 16px;
     margin-top:16px;
    border:1px solid var(--20-stroke,rgba(255,255,255,.2));
    background:var(--glass-card,rgba(35,38,48,.5));
    box-shadow:0 0 60px -20px rgba(255,255,255,.25) inset,0 0 15px 0 rgba(0,0,0,.15);
    backdrop-filter:blur(7.5px)
}
.result__top{
    margin-left: 16px;
     width:340px;
    height:121px;
    border-radius:20px;
    border:1px solid var(--20-stroke,rgba(255,255,255,.2));
    background:var(--glass-card,rgba(35,38,48,.5));
    box-shadow:0 0 60px -20px rgba(255,255,255,.25) inset,0 0 15px 0 rgba(0,0,0,.15);
    backdrop-filter:blur(7.5px)
}
.desWList {
    background: url(../../img/WP\ Book\ 1.webp);
    background-repeat: no-repeat;
    background-size: 100%;
    height:100px;
    width:117px;
}
.wMobList{
    background: url(../../img/Wmob.webp);
    background-repeat: no-repeat;
    background-size: 100%;
    height:85px;
    width:73px;
}

.mint1{
    background: url(../../img/Group\ 602.webp);
    background-repeat: no-repeat;
    background-size: 100%;
    width: 480px;
    height: 200px;
    border-radius:5px;
}
.mint2 {
    background: url(../../img/1000.webp);
    background-repeat: no-repeat;
    background-size: 100%;
    width: 480px;
    height: 200px;
    border-radius:5px;
}
.mint3{
    background: url(../../img/Claim\ your\ free\ SPN\ \(1\).webp);
    background-repeat: no-repeat;
    background-size: 100%;
    width: 480px;
    height: 200px;
    border-radius:5px;
}

.mint4 {
    background: url(../../img/1000\ \(1\).webp);
    background-repeat: no-repeat;
    background-size: 100%;
    width: 480px;
    height: 200px;
    border-radius:5px;
}
.boxPromoCard {
    display: flex;
    margin: 0 auto;
    max-width: 1030px;
}

.boxPromoCard  .freeMint:not(:first-child) {
    margin-left: 46px;
}
.blockRightCalcul {
    display: flex;
    justify-content: center;
}

 .container__header{
    position: relative;
    max-width: 1920px;
    height:80px;
    background:var(--glass-card,rgba(35,38,48,.5));
    backdrop-filter:blur(25px);
    display:flex;
    justify-content:center;
    margin:0 auto;
    z-index:3
}
.header__menu{
    display:flex;
    flex-wrap:wrap;
    justify-content:space-between
}
.nav{
    margin-top:29px;
    margin-left:46px
}
.box:hover,.btn__spin:hover,.link:hover{
    border-radius:20px;
    box-shadow:0 0 0 0 rgba(255,255,255,.9);
    animation:shadow-pulse 1.5s infinite
}
button:hover{
    box-shadow:0 0 0 0 rgba(255,255,255,.9);
    animation:shadow-pulse 1.5s infinite
}
@keyframes shadow-pulse{
    0%{
        box-shadow:0 0 0 0 rgba(255,255,255,.9)
    }
    70%{
        box-shadow:0 0 0 8px rgba(255,255,255,0)
    }
    100%{
        box-shadow:0 0 0 10px rgba(255,255,255,0)
    }
}
.logo__desc{
    display:block;
    margin-top:12px;
    cursor:pointer
}
.logo__mob{
    display:none
}
ul li a{
    text-transform:uppercase;
    text-decoration:none;
    color:#fff
}
li{
    list-style-type:none;
    display:inline
}
li:not(:first-child){
    margin-left:30px
}
.youtube-share-button {
    display: none;
}
.youtube-logo {
    display: none;
}
.btn-contact{
    align-items:center;
    display:flex
}
.link:hover{
    background-color:var(--dark-elements,rgba(243,167,42,1))
}
.link{
    background-image:url(../../../src/img/discord.svg);
    border-radius:20px;
    border:none;
    background-repeat:no-repeat;
    background-color:var(--dark-elements,rgba(0,0,0,.2));
    background-position:center;
    width:31px;
    height:31px;
    cursor:pointer
}
.twit{
  
    background-image:url(../../../src/img/twitter.svg);
    background-position:center;
    background-repeat:no-repeat;
    width:31px;
    height:31px;
    margin-right:8px;
    margin-left:227px
}
.play:hover{
    background: linear-gradient(180deg, #F08019 0%, #F6CD3A 100%);
    box-shadow: 0px 0px 30px 0px rgba(240, 130, 26, 0.80) inset, 0px 0px 20px 0px rgba(240, 130, 26, 0.80) inset, 0px 0px 10px 0px rgba(240, 130, 26, 0.80) inset;
    backdrop-filter: blur(7.5px);
}
.play{
    width:180px;
    height:50px;
    border-radius:40px;
    background: var(--Glass-Card, rgba(35, 38, 48, 0.50));
    box-shadow: 0px 0px 30px 0px rgba(240, 130, 26, 0.80) inset, 0px 0px 20px 0px rgba(240, 130, 26, 0.80) inset, 0px 0px 10px 0px rgba(240, 130, 26, 0.80) inset;
    backdrop-filter: blur(7.5px);
    border:none;
    margin-left:16px;
    cursor:pointer;
    text-shadow: 0px 0px 1.565999984741211px #9F2BAD, 0px 0px 3.131999969482422px #9F2BAD, 0px 0px 10.961999893188477px #F08019;
    font-family: Rubik;
    font-size: 32px;
    font-style: normal;
    font-weight: 900;
}
@keyframes pulsey{
    0%{
        box-shadow:0 0 5.06374168395996px 0 #f1831a,0 0 14.361549377441406px 0 #f1831a;
        transform:scale(1)
    }
    25%{
        box-shadow:0 0 10.06374168395996px 0 #f1831a,0 0 18.361549377441406px 0 #f1831a;
        transform:scale(1)
    }
    50%{
        box-shadow:0 0 19.06374168395996px 0 #f1831a,0 0 33.361549377441406px 0 #f1831a;
        transform:scale(1.2)
    }
    75%{
        box-shadow:0 0 19.06374168395996px 0 #f1831a,0 0 33.361549377441406px 0 #f1831a;
        transform:scale(1.2)
    }
    100%{
        box-shadow:0 0 5.06374168395996px 0 #f1831a,0 0 14.361549377441406px 0 #f1831a;
        transform:scale(1)
    }
}
.box__left__menu{
    display:flex;
    justify-content:center
}
.logo{
    width:55px;
    height:55px
}
.logo__desc{
    cursor:pointer;
    display:block;
    margin-top:15px
}
.header__menu{
    width:1300px;
}
.freeMint{
    margin:0 auto;
}
.freeMint:hover{
    border-radius:5px;
    box-shadow:0 0 0 0 rgba(255,255,255,0.9);
    animation:shadow-pulse 1s infinite;
    cursor:pointer;
}
/* @keyframes shadow-pulse{
    0{
        box-shadow:0 0 0 0 rgba(255,255,255,0.9)
    }
    70%{
        box-shadow:0 0 0 8px rgba(255,255,255,0)
    }
    100%{
        box-shadow:0 0 0 10px rgba(255,255,255,0)
    }
} */
section{
    position:relative;
    margin:0 auto;
    background-color:#1c1f28;
    background-image:url('../../../src/img/Group\ 236\ 1\ \(5\).webp');
    background-position:center;
    background-repeat:no-repeat;
    max-width:1920px;
    height:100%;
}
.container__main{
    max-width:1920px;
    z-index:1;
    position:relative;
    margin:0 auto;
    padding-top:201px;
    padding-bottom:174px;
    display:flex;
    justify-content:center;
    flex-direction:column;
    background-color:#1c1f2895;
    background-image:url('../../../src/img/Dots\ \(1\).webp');
    background-repeat:no-repeat;
    background-position:center
}
.container__video{
    pointer-events: none;
    position:absolute;
    top:55%;
    left:50%;
    height:100%;
    width: 100%;
    z-index:-1;
    transition:height 1s var(--easing-authentic-motion),width 1s var(--easing-authentic-motion),opacity 1s var(--easing-authentic-motion);
    transform:translate(-50%,-50%)
}
#video__promo{
    pointer-events: none;
    opacity: 0.5;
    transform:scale(1.5099);
    overflow:hidden;
}
iframe {
    width: 1271px;
    height: 715px;
}
.title_one{
    display:flex;
    width:174px;
    justify-content:space-between;
    align-items:center;
    margin:0 auto
}
.content__logo{
    height:389px
}
.mobil{
    display:none;
    background: url(../../img/Logo\ 01\ 3\ 1\ \(1\).webp);
    background-repeat: no-repeat;

    height: 229px;

}
.desc{
    background: url(../../img/Logo\ 01\ 3\ 1.webp);
    background-repeat: no-repeat;
    width: 512px;
    height: 229px;
    display:block;
    margin:0 auto
}
.now{
    width:220px;
    height:45px;
    border-radius:30px;
    margin:0 auto;
    line-height:50px;
    margin-bottom:42px;
    animation:pulsey 4s infinite
}
.btn__content{
    margin:0 auto;
    width:282px;
    height:32px;
    margin-top:37px;
    margin-bottom:49px
}
.welcome{
    display:flex;
    height:30px;
}
.spin{
    height:30px;
}
.btn_runes, .btn_prem{
    display: flex;
     flex-direction: column;
}
 .btn__spiney.btn_runes,.btn__spiney.btn_prem{
    width: 103px;
    height: 59px;
}
.premAndRuna{
    display:flex;
    width:210px;
    margin: 0 auto;
}
 .spin h4,.welcome h4{
    line-height:39px;
    margin:0
}
.lenta1{
    display:flex;
    max-width:150%;
    height:56px;
    background:var(--glass-card,rgba(35,38,48,.5));
    backdrop-filter:blur(25px);
    position:relative;
    top:154px;
    left:0;
    overflow:hidden;
    white-space:nowrap;
    z-index:99;
    -webkit-backdrop-filter:blur(25px)
}
.text2{
    display:flex;
    animation:marquee 50s linear infinite;
    position:absolute;
    top:7px;
    left:85%;
    white-space:nowrap;
    z-index:5;
    width:100%
}
.text{
    display:flex;
    animation:marquee 50s linear infinite;
    position:absolute;
    top:7px;
    left:-1%;
    white-space:nowrap;
    z-index:5;
    width:100%
}
@keyframes marquee{
    0%{
        transform:translateX(-100%)
    }
    100%{
        transform:translateX(0)
    }
}
.lenta2{
    transform:rotate(356deg);
    background:var(--glass-card,rgba(35,38,48,.5));
    backdrop-filter:blur(25px);
    position:relative;
    width:100%;
    height:56px;
    top:179px;
    right:0;
    overflow:hidden;
    white-space:nowrap;
    -webkit-backdrop-filter:blur(25px)
}
.icon__lenta2{
    height:38px;
    background-repeat:no-repeat;
    background-position:center;
    display:flex;
    animation:marqueelenta2 60s linear infinite;
    position:absolute;
    z-index:2;
    top:0;
    left:100%;
    white-space:nowrap
}
.icon__lenta3{
    height:38px;
    background-repeat:no-repeat;
    background-position:center;
    display:flex;
    animation:marqueelenta2 60s linear infinite;
    position:absolute;
    z-index:2;
    top:0;
    left:0;
    white-space:nowrap
}
.player{
    transform:rotate(2deg);
    margin-right:10px;
    display:flex;
    flex-direction:column;
    margin-top:3px
}
.spn__bank p {
    font-family:Dongle;
    font-size: 20px;
    font-weight:300;
     line-height: 38px;
}
.player1{
    background:url(../../../src/img/promo.webp);
    background-repeat:no-repeat;
    background-position:center;
    width:150px;
    height:47px
}
.player2{
    background:url(../../../src/img/commo.webp);
    background-repeat:no-repeat;
    background-position:center;
    width:150px;
    height:47px
}
.player3{
    background:url(../../../src/img/unc.webp);
    background-repeat:no-repeat;
    background-position:center;
    width:150px;
    height:47px
}
.player4{
    background:url(../../../src/img/fros.webp);
    background-repeat:no-repeat;
    background-position:center;
    width:150px;
    height:47px
}
.player5{
    background:url(../../../src/img/viol.webp);
    background-repeat:no-repeat;
    background-position:center;
    width:150px;
    height:47px
}
.player6{
    background:url(../../../src/img/sunsh.webp);
    background-repeat:no-repeat;
    background-position:center;
    width:150px;
    height:47px
}
.swiper-slide{
    width:300px;
    height:420px
}
.f1,.f2,.f3,.f4,.f5,.s_1,.s_2,.s_3,.s_4,.s_5{
    background-repeat: no-repeat;
    background-size: 100%;
    width:300px;
    height:420px
}

.f1 {
    background: url(../../img/c1.webp)no-repeat;
    background-size: 100%;
}
.f2 {
    background: url(../../img/c2.webp)no-repeat;
    background-size: 100%;
}
.f3 {
    background: url(../../img/c3.webp)no-repeat;
    background-size: 100%;
}
.f4 {
    background: url(../../img/c4.webp)no-repeat;
    background-size: 100%;
}
.f5 {
    background: url(../../img/c5.webp)no-repeat;
    background-size: 100%;
}

.s_1{
    background: url(../../img/c11.webp)no-repeat;
    background-size: 100%;
}
.s_2{
    background: url(../../img/c222.webp)no-repeat;
    background-size: 100%;
}
.s_3{
    background: url(../../img/c33.webp)no-repeat;
    background-size: 100%;
}
.s_4{
    background: url(../../img/c44.webp)no-repeat;
    background-size: 100%;
}
.s_5{
    background: url(../../img/c55.webp)no-repeat;
    background-size: 100%;
}
@keyframes marqueelenta2{
    0%{
        transform:translateX(0)
    }
    100%{
        transform:translateX(-100%)
    }
}
.lenta3-container .text2{
    left:125%
}
.lenta4-container .lenta2{
    top:263px;
    right:-1px
}
.lenta3-container .lenta1{
    top:300px
}
.container__about{
    display:flex;
    justify-content:center;
    margin:0 auto;
    height:100%;
    background:url('../../../src/img/Group\ 237.svg');
    background-repeat:no-repeat;
    background-position-y:324px
}
.about__box{
    max-width:1920px;
    display:flex;
    margin:0 auto
}
.card__desc{
    display:block
}
.card__mobi{
    display:none
}
.legend__head{
    position:absolute;
    top:190px;
    left:99px
}
.card__desc{
    display:block;
    background: url(../../img/spinCard.webp);
    background-repeat: no-repeat;
    width:500px; 
    height:750px;
    
}

.card__descRotating {
    background: url(../../img/Group\ 234.webp);
    background-repeat: no-repeat;
    width: 552px;
    height: 552px;
}

.head__desc{
    background: url(../../img/Group\ 118\ \(5\).webp);
    background-repeat: no-repeat;
    background-size: 285px;
    width: 302px;
    height: 298px;
}
.card__mobi{
    display:none;
}
.head__desc{
    display:block;
}
.head__mobi{
    display:none;
}
.legend{
    position:absolute;
    top:90px;
    left:-36px
}
.discordFooter {
    background: url(../../img/discord.svg)no-repeat;
    background-size: 79%;
    background-position: 4px 3px;
    width: 29px;
    height: 22px;
}

.twitterFooter {
    background: url(../../img/twitter.svg)no-repeat;
    background-size: 79%;
    background-position: 4px 4px;
    width: 29px;
    height: 22px;
}
.spin_card{
    margin:226px 133px 222px 0;
    position:relative
}
.rotating{
    transform:rotate(0);
    animation:rotate 8s linear infinite
}
@keyframes rotate{
    from{
        transform:rotate(0)
    }
    to{
        transform:rotate(360deg)
    }
}
.info__spin{
    margin-top:368px
}
.container__about .btn__content{
    width:429px;
    height:56px
}
.title__box{
    margin-top:102px;
    margin-bottom:24px
}
.iconTextLenta {
    background: url(../../img/sl2.webp)no-repeat;
    background-repeat: no-repeat;
    background-size: 80%;
    width: 20px;
    height: 30px;
    margin: 3px 20px 0px 20px;
}

/*  */

.running-text-container {
    display: flex;
    height: 56px;
    width: 100%;
    overflow: hidden;
    background: var(--Glass-Card, rgba(35, 38, 48, 0.50));
    backdrop-filter: blur(25px);
    position: absolute;
    bottom: -4px;
    right: 0;
    z-index: 99;
    pointer-events: none;
}

.running-icon{
    display: flex;
    height: 56px;
    width: 100%;
    overflow: hidden;
    background: var(--Glass-Card, rgba(35, 38, 48, 0.50));
    backdrop-filter: blur(25px);
    position: absolute;
    bottom: 2px;
    right: 0;
    z-index: 98;
    pointer-events: none;
    transform: rotate(-3deg);
}

.running-icon.bottom-icon-lenta {
    bottom: 690px;
}

.running-text-container.bottomlenta {
    bottom: -296px;
   
}

.bottomlenta .BlockLenta1 {
    left: -535px;
}


.BlockLenta1 {
    display: flex;
    position: absolute;
    bottom: 16px;
    left: -537px;

}

.BlockLenta2{
    display: flex;
    position: absolute;
    bottom: 7px;
    left: -960px;
}
.player-content{
    display: flex;
    white-space: nowrap;
    margin-top: 0px;
    animation: runIcon 6s linear infinite;
}
.text-container {
    display: flex;
    white-space: nowrap;
    margin-top: 10px;
    animation: runText 3s linear infinite;
}


@keyframes runText {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(100%);
    }
}
@keyframes runIcon {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(-100%);
    }
}
.qwid {
    width:258px;
}

.welcome,
.spin {
    display: flex;
   
}

  .spin  .qwid{
    width: 172px;
  }

  
  .welcome  .qwid{
    width: 244px;
  }


/*  */

.wax {
    background: url(../../img/left1.svg);
    background-repeat: no-repeat;
    background-size: 100%;
    width: 89px;
    height: 32px;
}

.blockchain   {
    background: url(../../img/blockchain.svg);
    background-repeat: no-repeat;
    background-size: 100%;
    width: 79px;
    height: 15px;
}
.box{
    display:flex;
    justify-content:space-between;
    background:var(--glass-card,rgba(35,38,48,.5));
    border-radius:15px;
    width:330px;
    height:130px;
    margin:0 auto;
    cursor:pointer;
    transition:transform .3s ease-in-out;
    text-decoration:none
}
.box:hover{
    transform:scale(1.1)
}
.title__book{
    font-size:20px;
    font-weight:300;
    color:#fff;
    margin-top:46px;
    margin-left:62px
}
.icon__bookMob{
    display:none
}
.icon__bookDesc{
    margin-top:15px;
    margin-right:37px
}
.container__calculator{
    max-width:1049px;
    display:flex;
    justify-content:center;
    margin:0 auto
}
.btn__spiney{
    width:209px;
    height:85px;
    margin:0 auto;
    display:flex;
    flex-wrap:wrap;
    justify-content:space-between
}
.btn__spin{
    width:103px;
    height:26px;
    border-radius:20px;
    background:var(--dark-elements,rgba(0,0,0,.2))
}
.promo.active,.promo:hover{
    background:var(--grey-ellements,linear-gradient(180deg,#535d7a 0,#434d67 100%))
}
.common.active,.common:hover{
    background:var(--common,linear-gradient(180deg,#a3a7b6 0,#4e556b 100%))
}
.uncommon.active,.uncommon:hover{
    background:var(--uncommon,linear-gradient(180deg,#a4d685 0,#538e2e 100%))
}
.rare.active,.rare:hover{
    background:var(--rare,linear-gradient(180deg,#69cdff 0,#2e7cf5 100%))
}
.epic.active,.epic:hover{
    background:var(--epic,linear-gradient(180deg,#be76e4 0,#6d4aac 100%))
}
.legenda.active,.legenda:hover{
    background:var(--legend,linear-gradient(180deg,#ffd171 0,#8d6f36 100%))
}
.false.active,.false:hover,.lv.active,.lv:hover,.lvl.active,.lvl:hover,.prem2:hover,.prem2.active,.prem.active,.l:hover,.l.active,.true.active,.prem:hover,.true:hover{
    background:var(--important-elements,linear-gradient(180deg,#f08019 0,#f6cd3a 100%))
}
.blockCalcul{
    height: 600px;
    margin: 0 auto;
}
.set{
    height:600px;
    width:340px;
    border-radius:20px;
    border:1px solid var(--20-stroke,rgba(255,255,255,.2));
    background:var(--glass-card,rgba(35,38,48,.5));
    box-shadow:0 0 60px -20px rgba(255,255,255,.25) inset,0 0 15px 0 rgba(0,0,0,.15);
    backdrop-filter:blur(7.5px)
}
.set{
    margin-right:16px
}
.set .btn__content{
    margin-top:19px;
    margin-bottom:0
}
.btn__content.rune{
    margin-top:19px;
    height: 25px;
}
.btn_rune{
    height:24px
}
.result{
    margin-left:16px;
    position:relative
}
.b{
    width:219px;
    position:absolute;
    bottom:55px;
    left:66px
}
.head__mobi {
    background: url(../../img/Group\ 236\ \(1\).webp);
    background-repeat: no-repeat;
    background-size: 100%;
    width: 192px;
    height: 172px;
}
.center__block{
    position:relative;
    z-index:5;
    width:338px;
    display:flex;
    flex-direction:column;
    margin:0 auto;
    justify-content:center
}
.premRuna{
    display: flex;
     justify-content: space-between;
    width: 166px;
}
.center__block .calcul{
    display: none;
    width:236px;
    height:45px;
    margin-bottom:0;
    align-items:center;
    margin:0 auto;
    border-radius:30px;
    line-height:50px
}
.calcul{
    /* transition:box-shadow .3s ease-in-out; */
    background:var(--glass-card,rgba(35,38,48,.5));
    border:none;
    margin-left:16px;
    cursor:pointer
}
.calcul.active2,.calcul:hover{
    background:var(--important-elements,linear-gradient(180deg,#f08019 0,#f6cd3a 100%));
    box-shadow:0 0 19.06374168395996px 0 #f1831a,0 0 33.361549377441406px 0 #f1831a
}
.desc__calc{
    display:flex
}
.center__block .btn__content{
    font-size:20px;
    margin-top:16px;
    margin-bottom:38px;
    width:216px
}
.result .btn__content{
    width:252px;
    height:80px;
    line-height:16px;
    margin:0 auto;
    padding-top:21px;
    font-size:20px
}
.container__spinney{
    margin-top:236px
}
.container__spinney .btn__content{
    width:393px;
    height:34px
}
.swiper{
    max-width:300px;
    height:40%
}
.swiper-slide{
    text-align:center;
    font-size:18px;
    display:flex;
    justify-content:center;
    align-items:center
}
.swiper-slide img{
    display:block;
    width:100%;
    height:100%;
    object-fit:cover
}
.refMultiplier,.profMultiplier{
    height: 49px;
}
.refMultiplier .btn__spin, .profMultiplier .btn__spin{
    width: 66px;
}

.swiper-button-next:after,.swiper-container-rtl .swiper-button-prev:after{
    /* width: 22px;
    height: 44px; */
    content:'';
    color:var(--glass-card,rgba(255,255,255,.5));
    position:absolute;
    right:-134px
}
.swiper-button-prev:after,.swiper-container-rtl .swiper-button-prev:after{
   
    content:'';
    color:var(--glass-card,rgba(255,255,255,.5));
    position:absolute;
    left:-134px
}
.container__runes{
    margin-top:113px
}
.container__runes .btn__content{
    width:445px;
    height:34px
}
.player img{
    width:151px;
    height:40px
}
.container__fire{
    position:relative;
    margin-top:264px;
    display:flex;
    justify-content:center;
    flex-direction:column
}
.anim__icon1{
    background: url(../../img/29\ 1.webp);
    position:absolute;
    width:830px;
    height:925px;
    bottom:252px;
    right:0;
    animation:moveUpDown 10s infinite ease-in-out
}
.anim__icon2{
    background: url(../../img/fire\ 1.webp);
    position:absolute;
    width:698px;
    height:855px;
    bottom:-175px;
    animation:moveUpDown2 10s infinite ease-in-out
}
@keyframes moveUpDown{
    0%{
        transform:translateY(0) translateX(0)
    }
    25%{
        transform:translateY(-20px) translateX(0)
    }
    50%{
        transform:translateY(0) translateX(20px)
    }
    75%{
        transform:translateY(20px) translateX(0)
    }
    100%{
        transform:translateY(0) translateX(0)
    }
}
@keyframes moveUpDown2{
    0%{
        transform:translateY(0) translateX(0)
    }
    25%{
        transform:translateY(30px) translateX(0)
    }
    50%{
        transform:translateY(0) translateX(-30px)
    }
    75%{
        transform:translateY(30px) translateX(0)
    }
    100%{
        transform:translateY(0) translateX(0)
    }
}
.block__fire{
    display:flex;
    justify-content:center;
    margin-top:80px
}
.right{
    margin-left:24px
}
.left,.right{
    display:flex;
    flex-direction:column
}
.left__1,.left__2,.left__3,.left__4,.left__5,.right__1,.right__2,.right__3,.right__4,.right__5{
    position:relative;
    border-radius:20px;
    border:1px solid var(--20-stroke,rgba(255,255,255,.2));
    background:rgba(35,38,48,.5);
    box-shadow:0 0 60px -20px rgba(255,255,255,.25) inset,0 0 15px 0 rgba(0,0,0,.15);
    backdrop-filter:blur(7.5px)
}
.left__1{
    width:638px;
    height:163px
}
.left__2{
    width:638px;
    height:163px;
    margin-top:24px
}
.left__3{
    width:638px;
    height:180px;
    margin-top:24px
}
.left__4{
    width:638px;
    height:240px;
    margin-top:24px
}
.left__5{
    width:638px;
    height:190px;
    margin-top:24px
}
.right__1{
    width:638px;
    height:180px
}
.right__2{
    width:638px;
    height:210px;
    margin-top:24px
}
.right__3{
    width:638px;
    height:183px;
    margin-top:24px
}
.right__4{
    width:638px;
    height:160px;
    margin-top:24px
}
.right__5{
    width:638px;
    height:163px;
    margin-top:24px
}
.shatl{
    background: url(../../img/Shatlb.webp)no-repeat;
    background-size: 100%;
    width: 166px;
    height: 166px;
    position:absolute;
    top:0;
    right:42px
}
.left__1__title{
    background:linear-gradient(180deg,#69cdff 0,#2e7cf5 100%);
    -webkit-background-clip:text;
    -webkit-text-fill-color:transparent;
    width:331px;
    height:32px;
    position:absolute;
    left:83px;
    top:48px
}
.left__1 .btn__content{
    width:208px;
    position:absolute;
    top:51px;
    left:83px;
    text-align:left;
    font-size:20px
}
.left__2__title{
    background:linear-gradient(180deg,#be76e4 0,#6d4aac 100%);
    -webkit-background-clip:text;
    -webkit-text-fill-color:transparent;
    width:331px;
    height:32px;
    position:absolute;
    left:223px;
    top:38px
}
.biscuit{
    background: url(../../img/biscuit_dessert\ 1.webp)no-repeat;
    background-size: 100%;
    width: 139px;
    height: 100px;
    position:absolute;
    left:35px;
    top:17px
}
.left__2 .btn__content{
    width:330px;
    height:48px;
    position:absolute;
    top:36px;
    left:222px;
    text-align:left;
    font-size:20px
}
.fire__left__3{
    position:absolute;
    top:61px;
    left:504px;
    background:url('../../../src/img/fire\ 3\ \(1\)\ 1\ \(2\).png');
    background-repeat:no-repeat;
    width:142px;
    height:142px
}
.fon__left__3{
    display:block;
    background:url('../../../src/img/Group\ 246\ \(1\).png');
    background-repeat:no-repeat;
    max-width:698px;
    height:248px
}
.left__3__title{
    background:linear-gradient(180deg,#f08019 0,#f6cd3a 100%);
    -webkit-background-clip:text;
    -webkit-text-fill-color:transparent;
    width:401px;
    height:32px;
    position:absolute;
    left:145px;
    top:36px
}
.left__3 .btn__content{
    width:366px;
    font-size:20px;
    position:absolute;
    left:139px;
    top:45px
}
.fon_com1,.fon_com2{
    display:none
}
.fon__left__4{
    background:url('../../../src/img/coin\ 1\ \(2\).png');
    background-repeat:no-repeat;
    width:115px;
    height:149px;
    position:absolute;
    left:67px;
    top:46px
}
.left__4{
    height:200px
}
.left__4__title{
    background:linear-gradient(180deg,#be76e4 0,#6d4aac 100%);
    -webkit-background-clip:text;
    -webkit-text-fill-color:transparent;
    width:331px;
    height:60px;
    position:absolute;
    left:215px;
    top:24px
}
.left__4 .btn__content{
    width:338px;
    left:215px;
    top:56px;
    position:absolute;
    text-align:left;
    font-size:20px
}
.fon__left__5{
    background:url('../../../src/img/hourglass\ 1\ \(1\).png');
    background-repeat:no-repeat;
    width:93px;
    height:132px;
    position:absolute;
    left:464px;
    top:29px
}
.left__5 .btn__content{
    width:268px;
    left:59px;
    top:60px;
    position:absolute;
    text-align:left;
    font-size:20px
}
.left__5__title{
    background:linear-gradient(180deg,#f08019 0,#f6cd3a 100%);
    -webkit-background-clip:text;
    -webkit-text-fill-color:transparent;
    width:401px;
    height:32px;
    position:absolute;
    left:59px;
    top:58px
}
.iconMint1{
    height:200px;
}
.fon__right__1{
    background:url('../../../src/img/Designing\ 2_DesignThinking\ 1\ \(1\).png');
    background-repeat:no-repeat;
    width:144px;
    height:116px;
    position:absolute;
    left:70px;
    top:32px
}
.right__1 .btn__content{
    width:268px;
    left:272px;
    top:60px;
    position:absolute;
    text-align:left;
    font-size:20px
}
.right__1__title{
    background:linear-gradient(180deg,#be76e4 0,#6d4aac 100%);
    -webkit-background-clip:text;
    -webkit-text-fill-color:transparent;
    width:331px;
    height:65px;
    position:absolute;
    left:269px;
    top:32px
}
.fon__right__2{
    background:url('../../../src/img/growth_up_2_infoe\ 1\ \(1\).png');
    background-repeat:no-repeat;
    width:174px;
    height:174px;
    position:absolute;
    left:437px;
    top:18px
}
.right__2 .btn__content{
    width:375px;
    left:60px;
    top:47px;
    position:absolute;
    text-align:left;
    font-size:20px
}
.right__2__title{
    background:linear-gradient(180deg,#f08019 0,#f6cd3a 100%);
    -webkit-background-clip:text;
    -webkit-text-fill-color:transparent;
    width:331px;
    height:32px;
    position:absolute;
    left:60px;
    top:44px
}
.right__3 .btn__content{
    width:317px;
    left:264px;
    top: 61px;
    position:absolute;
    text-align:left;
    font-size:20px
}
.fon__right__3{
    background:url('../../../src/img/medal\ 1\ \(1\).png');
    background-repeat:no-repeat;
    width:96px;
    height:115px;
    position:absolute;
    left:68px;
    top:34px
}
.right__3__title{
    background:linear-gradient(180deg,#be76e4 0,#6d4aac 100%);
    -webkit-background-clip:text;
    -webkit-text-fill-color:transparent;
    width: 208px;
    height: 66px;
    position: absolute;
    left: 264px;
    top: 29px;
}
.fon__right__4{
    background:url('../../../src/img/Group\ 231\ \(1\).webp');
    background-repeat:no-repeat;
    background-position-x:-11px;
    background-position-y:0;
    border-top-left-radius:70px;
    width:635px;
    height:175px;
    position:absolute;
    left:-14px;
    top:-15px
}
.right__4__title{
    background:linear-gradient(180deg,#f08019 0,#f6cd3a 100%);
    -webkit-background-clip:text;
    -webkit-text-fill-color:transparent;
    width:401px;
    height:32px;
    position:absolute;
    left:119px;
    top:53px
}
.right__4 .btn__content{
    width:317px;
    left:158px;
    top:50px;
    position:absolute;
    text-align:left;
    font-size:20px
}
.fon__right__6{
    background:url('../../../src/img/pie_chart_1\ 1\ \(1\).png');
    background-repeat:no-repeat;
    width:154px;
    height:154px;
    position:absolute;
    left:24px;
    top:4px
}
.right__5 .btn__content{
    width:336px;
    left:202px;
    top:41px;
    position:absolute;
    text-align:left;
    font-size:20px
}
.mob_title{
    display:none
}
.right__5__title{
    background:linear-gradient(180deg,#69cdff 0,#2e7cf5 100%);
    -webkit-background-clip:text;
    -webkit-text-fill-color:transparent;
    width:401px;
    height:32px;
    position:absolute;
    left:202px;
    top:38px
}
.container__community{
    height:400px;
    display:flex;
    justify-content:center;
    margin-top:500px
}
.box__community{
    width:1090px;
    height:271px;
    border-radius:20px;
    background:var(--glass-card,rgba(35,38,48,.5));
    box-shadow:0 0 60px -20px rgba(255,255,255,.25) inset,0 0 15px 0 rgba(0,0,0,.15);
    backdrop-filter:blur(7.5px);
    position:relative;
    padding-top:73px
}
.fon_com{
    position:absolute;
    background:url('../../../src/img/Group\ 233.svg');
    width:1032px;
    height:189px;
    top:57px;
    left:43px
}
.box__community .btn__content{
    width:575px;
    margin:0 auto;
    padding-top:12px;
    padding-bottom:28px
}
.join{
    position:relative;
    width:220px;
    height:45px;
    border-radius:30px;
    background:var(--dark-elements,rgba(0,0,0,.2));
    margin:0 auto
}
.j{
    line-height:46px
}
.twiter img{
    margin:5px
}
.dickord img{
    margin-top:5px;
    margin-left:4px
}
.dickord,.twiter{
    position:absolute;
    height:31px;
    width:31px;
    border-radius:20px;
    background:var(--important-elements,linear-gradient(180deg,#f08019 0,#f6cd3a 100%));
    box-shadow:0 0 19.06374168395996px 0 #f1831a,0 0 33.361549377441406px 0 #f1831a
}
.twiter{
    position:absolute;
    top:7px;
    right:7px
}
.dickord{
    position:absolute;
    top:7px;
    left:7px
}
.dickord:hover,.twiter:hover{
    box-shadow:0 0 0 0 rgba(255,255,255,.9);
    animation:shadow-pulse 1.5s infinite
}
.r a{
    display:inline-block;
    transition:transform .3s
}
.r a.active,.r a:hover{
    transform:scale(1.1)
}
.footer{
    display:flex;
    width:100%;
    height:80px;
    background:var(--glass-card,rgba(35,38,48,.5));
    backdrop-filter:blur(25px);
    margin:0 auto
}
.content__footer{
    position:relative;
    z-index:5;
    display:flex;
    margin:0 auto;
    width:406px;
    padding-top:20px
}

.f_1{
    margin-right:24px;
    width:170px;
    height:24px
}
.f_2{
    margin-left:24px;
    width:170px;
    height:24px
}
.f_1 p{
    text-align:right
}
.f_2 a{
    text-decoration:none
}
.f_2 p{
    text-align:left
}
.mob__content{
    display:none
}

.footer-icon {
    background: url(../../img/s.webp);
    background-repeat: no-repeat;
    height: 40px;
    width: 25px;
}
@media (max-width: 1440px){
    #video__promo{
        transform: scale(1.5);
    }
}
@media (max-width:1380px){
   
    section{
        background:url('../../../src/img/Group\ 236\ 1\ \(6\).webp');
        background-repeat:no-repeat
    }
    .swiper{
        max-width:300px;
        height:40%
    }
    .text2{
        display:none
    }
    .icon__lenta2{
        display:none
    }
    .anim__icon1{
        right:577px;
        top:100px
    }
    .anim__icon2{
        left:100px
    }
    .nav{
        margin-left:30px
    }
    .twit{
        margin-left:130px
    }
    .anim__icon{
        display:none
    }
    .block__fire{
        flex-direction:column;
        margin:0 auto;
        padding-top:60px
    }
    .container__runes,.container__spinney{
        margin-top:200px
    }
    .spinney_slider__mob{
        display:block
    }
    .twit{
        margin-left:75px
    }
    .container__about{
        flex-direction:column
    }
    .center__block{
        padding:55px 0 55px 0
    }
    .set{
        margin:0 auto
    }
    .right{
        margin-top:24px
    }
    .box__community{
        max-width:600px
    }
    .fon_com,.runa,.spinney_slider{
        display:none
    }
    .container__about .btn__content{
        max-width:300px
    }
    .right{
        margin-left:0
    }
    .left__1,.left__2,.left__3,.left__4,.left__5,.right__1,.right__2,.right__3,.right__4,.right__5{
        max-width:600px
    }
    .fire__left__3{
        left:466px
    }
    .fon_com1{
        right:16px
    }
    .fon_com2{
        left:16px
    }
    .fon_com1,.fon_com2{
        width:28px;
        height:28px;
        background:url('../../../src/img/AnyConv.com__image\ 48.webp');
        background-repeat:no-repeat;
        display:block;
        position:absolute;
        top:16px
    }
    .lenta1{
        backdrop-filter:blur(25px)
    }
    .swiper-slide{
        width:300px;
        height:420px
    }
    .mint4,
    .mint3,
    .mint2,
    .mint1{
        width:300px;
        height:124px;
        margin-bottom: 40px;
    }

   
    .iconMint1{
        height: 124px;
    }

    
}
@media (max-width:970px){
    .blockRightCalcul .result__top,
.blockRightCalcul .result__bottom{
    margin-left: 0px;
}
    iframe {
        width: 900px;
        height: 636px;
    }
    .header__menu{
        justify-content: center;
    }
  
    #video__promo{
        display:none;
    }
    .mob__content{
        display:flex;
        order:1
    }
    .center__block .btn__content{
        margin-bottom:10px
    }
    .anim__icon1{
        display:none
    }
    .anim__icon2{
        display:none
    }
    .spin_card{
        display:flex;
        margin:160px 0 100px 14px
    }
    .nav{
        display:none
    }
    .twit{
        margin-left:35px
    }
    .box__community{
        max-width:320px
    }
    .desc__calc{
        display:none
    }
    .container__calculator{
        flex-direction:column;
        margin:0 auto;
        padding-top:60px
    }
    .about__box{
        flex-direction:column;
        height:100%
    }
    .info__spin{
        margin:0
    }
    .box__community{
        width:320px;
        position:relative;
        padding-top:31px;
        height:260px
    }
    .box__community .btn__content{
        width:300px
    }
    .desctop{
        display:none
    }
    .mob_title{
        display:flex;
        justify-content:center;
        flex-direction:column;
        order:-1;
        margin-top:200px;
        margin-bottom:20px
    }
    .box__community h2:lang(ru){
        width:300px;
        font-size:20px
    }
}
    
@media (max-width:650px){

    .play {
        width: 119px;
        height: 36px;
        border-radius: 40px;
        font-size: 22px;
  
    }

    .running-icon.bottom-icon-lenta {
        bottom: 731px;
    }
    .f1,.f2,.f3,.f4,.f5,.s_1,.s_2,.s_3,.s_4,.s_5{
        width:200px;
        height:280px
    }

    .running-icon{
        bottom: -141px;
      
    }

    .card__descRotating {
        background-size: 100%;
        width: 231px;
        height: 231px;
    }

    .content__footer {
        max-width: 320px;
    }

    .running-text-container {
 
    bottom: -93px;
   
}
    .container__spinney .btn__content,
    .container__runes .btn__content {
        width: 320px;
        height: 34px;
    }
     .content__logo{
        height: 300px;
    }
    .mint4,
    .mint3,
    .mint2,
    .mint1{
        width:145px;
        height:61px;
        margin-bottom: 40px;
    }

   
    .iconMint1{
        height: 61px;
    }

    /* .boxPromoCard {
    max-width: ;
    } */
    .b{
        width:194px;
        bottom:54px;
        left:57px
    }
    .fire__left__3{
        display:none
    }
    /* .container__runes .btn__content,.container__spinney .btn__content,.content__footer,.result__top,.result__bottom{
        margin-left: 0px;
    } */
    .result__bottom,.result,.set{
        width:300px
    }
    .swiper-button-next:after,.swiper-container-rtl .swiper-button-prev:after{
        right:-60px
    }
    .swiper-button-prev:after,.swiper-container-rtl .swiper-button-prev:after{
        left:-60px
    }
    .swiper{
        width:200px
    }
    .now{
        width:168px
    }
    .twit{
        margin-left:12px
    }
    .logo__desc{
        display:none
    }
    .logo__mob{
        display:block;
        backdrop-filter:none;
        -webkit-backdrop-filter:none;
        margin-top:0px
    }
    .mobil{
        display:block;
        margin:0 auto;
        width: 300px;
    }
    .desc{
        display:none
    }
    .card__desc{
        display:none
    }
    .card__mobi{
        display:block;
        position:relative;
        width: 290px;
        height: 430px;
        background: url(../../img/group-253-1.webp);
        background-repeat: no-repeat;
    }
    .head__desc{
        display:none
    }
    .head__mobi{
        display:block
    }
    .legend{
        position:absolute;
        top:101px;
        overflow:hidden;
        left:20px
    }
    .legend__head{
        position:absolute;
        top: 102px;
        left: 36px;
    }
    .container__main .btn__content{
        margin-top:15px
    }
    .box{
        width:250px;
        height:98px;
        transition:transform .3s ease-in-out
    }
    .icon__bookDesc{
        display:none
    }
    .icon__bookMob{
        display:block;
        height:85px;
        width:73px;
        margin-top:15px;
        margin-right:30px
    }
    .title__book{
        margin-top:24px;
        margin-left:44px
    }
    .block__fire .btn__content:lang(ru),.content__footer p:lang(ru),.j:lang(ru){
        font-size:12px
    }
    .right__3 .btn__content:lang(ru),.right__5 .btn__content:lang(ru){
        width:235px;
        left:84px
    }
    .card__text:lang(ru){
        width:250px;
        height:57px
    }
    .left__1{
        width:316px;
        height:162px
    }
    .shatl{
        width:96px;
        height:106px;
        position:absolute;
        top:56px;
        right:8px
    }
    .left__1__title{
        top: 18px;
        left:20px;
        width:282px
    }
    .left__1 .btn__content{
        width:173px;
        top: 23px;
        left:25px
    }
    .loader-animation{
        position:absolute;
        top:10%;
        left:10%;
        transform:translate(-10%,-10%);
        width:100px;
        height:100px
    }
    .left__2{
        width:316px;
        height:185px
    }
    .biscuit{
        left: 12px;
        top: 72px;
        width:96px;
        height:106px
    }
    .center__block{
        width:300px
    }
    .left__2__title{
        left:32px;
        top:16px;
        width:274px
    }
    .result .block__bank{
        width:248px;
        position:absolute;
        left:25px;
        top:230px;
        z-index:5
    }
    .left__2 .btn__content{
        width:195px;
        top:16px;
        left:97px;
        text-align:right
    }
    .left__3{
        width:316px;
        height:213px
    }
    .fire__left__3{
        position:absolute;
        top:61px;
        left:504px;
        background:0 0;
        background-repeat:no-repeat;
        width:142px;
        height:142px
    }
    .fon__left__3{
        background:url('../../../src/img/Group\ 251.webp');
        background-repeat:no-repeat;
        max-width:320px;
        height:301px;
        background-position:right;
        background-position-y:-3px
    }
    .left__3__title{
        width:157px;
        height:92px;
        position:absolute;
        left:130px;
        top:16px
    }
    .left__3 .btn__content{
        position:absolute;
        width:211px;
        top:71px;
        left:24px;
        text-align:left
    }
    .left__4{
        width:316px;
        height:240px
    }
    .fon__left__4{
        background:url('../../../src/img/coin\ 1.webp');
        background-repeat:no-repeat;
        width:76px;
        height:81px;
        position:absolute;
        left:222px;
        top:58px
    }
    .left__4__title{
        text-align: left;
        width:291px;
        height:92px;
        position:absolute;
        left:24px;
        top:16px
    }
    .left__4 .btn__content{
        position:absolute;
        width:213px;
        top:52px;
        left:24px;
        text-align:left
    }
    .left__5{
        width:316px;
        height:162px
    }
    .fon__left__5{
        background:url('../../../src/img/hourglass\ 1.webp');
        background-repeat:no-repeat;
        width:65px;
        height:92px;
        position:absolute;
        left:238px;
        top:46px
    }
    .left__5__title{
        width:202px;
        height:56px;
        position:absolute;
        left:24px;
        top:16px
    }
    .left__5 .btn__content{
        position:absolute;
        width:209px;
        top:44px;
        left:24px;
        text-align:left
    }
    .right__1{
        width:316px;
        height:180px
    }
    .fon__right__1{
        background:url(../../../src/img/designing-2-designthinking-1.webp);
        background-repeat:no-repeat;
        width:84px;
        height:67px;
        position:absolute;
        left:24px;
        top:95px
    }
    .right__1__title{
        width:216px;
        height:56px;
        position:absolute;
        left:16px;
        top:20px
    }
    .right__1 .btn__content{
        position:absolute;
        width:179px;
        top:50px;
        left:124px;
        text-align:left
    }
    .right__2{
        width:316px;
        height:232px
    }
    .fon__right__2{
        background:url('../../../src/img/growth_up_2_infoe\ 1.webp');
        background-repeat:no-repeat;
        width:71px;
        height:106px;
        position:absolute;
        left:237px;
        top:10px
    }
    .right__2__title{
        width:223px;
        height:56px;
        position:absolute;
        left:24px;
        top:22px
    }
    .right__2 .btn__content{
        position:absolute;
        width:217px;
        top:24px;
        left:24px;
        text-align:left
    }
    .right__3{
        width:316px;
        height:200px
    }
    .fon__right__3{
        background:url(../../../src/img/medal-1.webp);
        background-repeat:no-repeat;
        width:62px;
        height:77px;
        position:absolute;
        left:20px;
        top:93px
    }
    .right__3__title{
        width:217px;
        height:56px;
        position:absolute;
        left:50px;
        top:16px
    }
    .right__3 .btn__content{
        position:absolute;
        width:197px;
        top:47px;
        left:106px;
        text-align:left
    }
    .right__4{
        width:316px;
        height:137px
    }
    .fon__right__4{
        background:url('../../../src/img/Any3d\ 2.webp');
        background-repeat:no-repeat;
        width:93px;
        height:94px;
        position:absolute;
        left:221px;
        top:30px
    }
    .right__4__title{
        width:248px;
        height:56px;
        position:absolute;
        left:54px;
        top:18px
    }
    .content__footer p{
        font-size:15px
    }
    .right__4 .btn__content{
        position:absolute;
        width:197px;
        top:47px;
        left:54px;
        text-align:center
    }
    .right__5{
        width:316px;
        height:176px
    }
    .fon__left__4:lang(ru){
        left:234px
    }
    .biscuit:lang(ru),.fon__right__3:lang(ru),.fon__right__6:lang(ru){
        left:4px
    }
    .left__3 .btn__content:lang(ru),.left__3__title:lang(ru){
        left:102px
    }
    .right__4 .btn__content:lang(ru){
        text-align:left
    }
    .fon__right__6{
        display:block;
        background:url('../../../src/img/AnyConv.com__pie_chart_1\ 1.webp');
        width:69px;
        height:89px;
        position:absolute;
        left:28px;
        top:46px
    }
    .right__5__title{
        width:230px;
        height:56px;
        position:absolute;
        left:54px;
        top:20px
    }
    .right__5 .btn__content{
        display:block;
        position:absolute;
        width:175px;
        top:20px;
        left:111px;
        text-align:left
    }
}
@media (max-width:400px){
    #video__promo{
        display: none;
    }
    iframe {
        width: 320px;
        height: 182px;
    }
    .container__video{
        top:50%
    }
    .container__main{
        padding-bottom:0
    }
    .container__header{
        height:120px;
        padding-bottom:10px;
        padding-top:10px
    }
    .header__menu{
        display:flex;
        flex-direction:column;
        flex-wrap:nowrap;
        justify-content:center
    }
    .nav{
        display:none
    }
    .center__block .btn__content{
        width:244px
    }
    .btn-contact{
        margin:0 auto
    }
    .logo{
        margin:0 auto
    }
    .lenta1{
        top:28px
    }
    .lenta2{
        top:42px
    }
}
 
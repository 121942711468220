.logo__lend {
    background: url(../../../src/img/Logo\ Mini\ 7.webp);
    background-repeat: no-repeat;
    height: 50px;
    width: 50px;
    margin-top: 15px;
 
}

@media (max-width: 400px){
    .logo__lend {
        margin-top: -3px;
    }
}